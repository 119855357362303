main[data-barba-namespace='news'] {
  @include grid(2, 4);
  margin-top: var(--vertical-space-top);
  padding-bottom: var(--gutter);

  @include viewports(('desktop', 'large')) {
    @include grid(1, 5);
    .new {
      display: flex;
      gap: var(--gutter);

      figure {
        @include grid(1, 2);
        flex: none;
        margin-left: unset;
        margin-bottom: unset;
      }
    }
  }

  @include viewport('mobile') {
    @include grid(1, 5);
  }

  & > ul li + li {
    margin-top: var(--vertical-space);
    border-top: var(--border);
    padding-top: var(--vertical-space);
  }
}
