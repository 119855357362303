main[data-barba-namespace='error'] {
  @include font('body');
  margin-top: var(--vertical-space-top);
  padding: 0 var(--gutter) var(--gutter);

  h2 {
    @include underline;
    margin-bottom: var(--vertical-space-small);
  }
}
