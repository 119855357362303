[data-template='default'] {
  &:not(.is-loading) main {
    @include fade-in;
    animation-fill-mode: backwards;
  }

  @if $debug {
    main::after {
      @include inset(0, fixed);
      z-index: 999;
      content: '';
      opacity: 0.5;
      pointer-events: none;
      background-size: calc((100vw - var(--gutter)) / var(--cols));
      background-image: linear-gradient(to right, transparent var(--gutter), rgba(255, 0, 0, 0.1) 0);
    }
  }
}
