main[data-barba-namespace='exhibitions'] {
  padding-top: var(--vertical-space-top);

  & > ul {
    display: grid;
    padding: 0 var(--gutter) var(--gutter);
    gap: var(--vertical-space) var(--gutter);
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;

    @include viewport('mobile') { grid-template-columns: repeat(1, 1fr); }
    @include viewport('large') { grid-template-columns: repeat(3, 1fr); }
  }

  & > ul > li {
    @include font('menu');

    a {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      gap: var(--vertical-space-small);
    }

    h2 {
      @include underline(transparent);
      align-self: flex-start;
    }

    &:hover h2 {
      text-decoration-color: currentColor;
    }
  }
}
