main[data-barba-namespace='home'] {
  .slideshow {
    @include font('menu');
    @include grid(1, 5);
    position: fixed;
    top: var(--vertical-space-top);
    bottom: calc(var(--vertical-space-small) * 2 + var(--gutter));
  }

  figure {
    position: relative;

    height: auto;
    margin: 0 auto;
    aspect-ratio: var(--aspect-ratio);

    max-height: calc(100% - var(--gutter));

    img {
      position: absolute;

      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: 50% 0%;
    }

    figcaption {
      display: flex;
      align-items: flex-end;

      position: absolute;
      top: calc(var(--vertical-space-small) + 1em);

      width: 100%;
      aspect-ratio: var(--aspect-ratio);
      color: var(--color-dim);

      a, span {
        @include easing(color);

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  figcaption a:hover {
    color: var(--color-text);
  }

  .languages {
    position: fixed;

    right: var(--gutter);
    bottom: var(--gutter);
  }
}
