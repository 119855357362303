main[data-barba-namespace='interviews'] {
  @include grid(2, 4);
  margin-top: var(--vertical-space-top);
  padding-bottom: var(--gutter);

  @include viewport('mobile') {
    @include grid(1, 5);
  }

  & > ul li + li {
    margin-top: var(--vertical-space);
    border-top: var(--border);
    padding-top: var(--vertical-space);
  }
}
