.new {
  figure {
    margin-bottom: var(--vertical-space);
  }

  h2 {
    @include underline;
    margin-bottom: var(--vertical-space-small);
  }
}
