main[data-barba-namespace='exhibition'] {
  margin-top: var(--vertical-space-top);
  padding: 0 var(--gutter);

  header {
    margin-bottom: var(--vertical-space-small);
  }

  h2 {
    @include underline;
  }

  .gallery {
    padding-bottom: var(--gutter);

    figure + figure {
      margin-top: var(--vertical-space);
    }

    img[data-orientation='portrait'] {
      max-height: 100vh;
      width: 100%;
      object-fit: contain;
    }

    audio, iframe, video {
      margin-top: var(--vertical-space);
    }
  }

  .credits {
    @include font('menu');
    color: var(--color-dim);
    text-align: right;
    margin-top: var(--vertical-space-small);
  }
}
