main[data-barba-namespace='works'] {
  padding-top: calc(var(--vertical-space) + var(--vertical-space-small));

  .filters {
    @include no-select;
    @include font('menu');
    @include grid(1, 5);

    z-index: 2;
    position: sticky;
    display: flex;
    text-transform: lowercase;
    margin-bottom: calc(var(--vertical-space-top) - var(--vertical-space) - var(--vertical-space-small) - 1em * var(--line-height-menu));
    white-space: nowrap;
    overflow: hidden;

    @include viewport('mobile') {
      margin-top: var(--gutter);
      flex-direction: column;
    }
  }

  .works {
    display: grid;
    align-items: flex-end;
    padding: 0 var(--gutter) var(--gutter);
    gap: var(--vertical-space) var(--gutter);
    grid-template-columns: repeat(4, 1fr);

    @include viewport('mobile') { grid-template-columns: repeat(1, 1fr); }
    @include viewport('tablet') { grid-template-columns: repeat(3, 1fr); }
    @include viewport('large') { grid-template-columns: repeat(5, 1fr); }
  }

  .work {
    line-height: 0;

    a {
      display: flex;
      flex-direction: column;
      gap: var(--vertical-space-small);
    }

    h2 {
      @include underline(transparent);
      @include font('menu');
      align-self: flex-start;
      line-height: 1;
    }

    .figure-wrapper {
      position: relative;
    }

    figure:last-child:not(:first-child) {
      @include easing(opacity);
      --easing: 1200ms;

      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;

      opacity: 0;

      img {
        width: 100%;
        height: 100%;
        object-position: var(--focus-x, 50%) var(--focus-y, 50%);
        object-fit: cover;
      }
    }

    &:hover h2 {
      text-decoration-color: var(--color-text);
    }

    &:hover figure:last-child {
      opacity: 1;
    }
  }
}
