.text {
  header {
    margin-bottom: var(--vertical-space-small);

    * {
      display: inline;
    }

    *:empty {
      display: none;
    }

    *:not(:last-child)::after {
      content: ',\00A0';
    }

    h2 {
      @include underline;
      font-style: italic;
    }

    span {
      @include underline;
    }
  }
}
