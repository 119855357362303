html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-padding-top: var(--vertical-space-top);
  @include viewports(('tablet', 'desktop', 'large')) {
    overscroll-behavior: none;
  }
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
  @include font('body');
  font-family: 'Poppins', sans-serif;

  min-height: 100%;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  background: var(--color-background);
  color: var(--color-text);

  @if $debug {
    &::before {
      content: '';
      position: absolute;
      top: var(--vertical-space-top);
      left: 0;
      right: 0;
      border-top: 1px solid cyan;
    }
  }
}

::selection  {
  background: var(--color-text);
  color: var(--color-background);
  text-shadow: none;
}

[data-photoswipe] {
  cursor: pointer;
  cursor: zoom-in;
}

figure figcaption {
  @include font('menu');
  text-align: right;
  margin-top: var(--vertical-space-small);
  color: var(--color-dim);
}

img {
  @include no-select;
  border: 0;
  -ms-interpolation-mode: bicubic;
  width: 100%;
  height: auto;
  vertical-align: bottom;

  &[data-lazyload]{
    &[data-loaded='true'] {}
    &[data-decoded='true'] {
      @include fade-in;
    }
  }
}

a {
  @include underline(transparent);

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  color: inherit;

  p & {
    text-decoration-color: currentColor;
  }
}

p + p {
  margin-top: var(--vertical-space-small);
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

audio, iframe, video {
  width: 100%;
}

iframe, video {
  aspect-ratio: 16/9;
}
