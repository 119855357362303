@import '~photoswipe/dist/photoswipe';
@import '~photoswipe/dist/default-skin/default-skin';

@import 'sass/reset';
@import 'sass/helpers';

$debug: false;

$max-width: 1920px;
$breakpoints: (
  'large': ($max-width, 9999px),
  'desktop': (1080px, $max-width),
  'tablet': (768px, 1080px),
  'mobile': (0px, 768px)
);

@mixin viewport ($key) {
  $breakpoint: map-get($breakpoints, $key);
  $min: nth($breakpoint, 1);
  $max: nth($breakpoint, 2);
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin viewports ($keys) {
  @each $key in $keys {
    @include viewport ($key) {
      @content;
    }
  }
}

:root {
  --color-background: #F7F7F7;
  --color-text: #000000;
  --color-dim: rgba(0 0 0 / 45%);

  --cols: 5;
  --gutter: 4.0rem;
  --vertical-space: 6rem;
  --vertical-space-top: 20rem;
  --vertical-space-small: var(--font-size-menu);

  --font-size-menu: 1.4rem;
  --font-weight-menu: 300;
  --line-height-menu: 1.5;

  --font-size-body: 1.8rem;
  --font-weight-body: 300;
  --line-height-body: 1.45;

  --font-size-large: 3.0rem;
  --font-weight-large: 300;
  --line-height-large: 1.1;

  --bold: 400;
  --border: 1px solid rgba(0 0 0 / 10%);
  --easing: 600ms;

  @include viewport('mobile') {
    --gutter: 2rem;
    --vertical-space: 4rem;
    --vertical-space-top: 12rem;
  }

  @include viewport('tablet') {
    --gutter: 3rem;
    --vertical-space: 4rem;
  }

  @media (max-height: 800px) {
    --gutter: 3rem;
    --vertical-space: 4rem;
    --vertical-space-top: 10rem;
  }
}

@mixin font ($suffix) {
  font-size: var(--font-size-#{$suffix});
  font-weight: var(--font-weight-#{$suffix});
  line-height: var(--line-height-#{$suffix});
}

@mixin underline ($color: currentColor) {
  @include easing(text-decoration-color);
  text-decoration: underline;
  text-underline-offset: 0.2em;
  text-decoration-thickness: 1px;
  text-decoration-color: $color;
}

@mixin grid ($from: 1, $to: 12) {
  margin-left: calc(var(--gutter) + #{$from - 1} * ((100vw - var(--gutter)) / var(--cols)));
  width: calc(#{$to - $from + 1} * ((100vw - var(--gutter)) / var(--cols)) - var(--gutter));
}

@keyframes fade-in {
  0% { opacity: 0; pointer-events: none; }
  100% { opacity: 1; pointer-events: auto; }
}

@mixin fade-in ($delay: 0ms) {
  animation: fade-in var(--easing) $delay cubic-bezier(.04,-0.01,.22,1) forwards;
}

@import 'sass/base';

@import 'components/Languages';
@import 'components/Filter';
@import 'components/Menu';
@import 'components/New';
@import 'components/Text';
@import 'components/Interview';
@import 'components/Photoswipe';
@import 'components/Slideshow';

@import 'templates/default';

@import 'views/biography';
@import 'views/contact';
@import 'views/error';
@import 'views/exhibition';
@import 'views/exhibitions';
@import 'views/home';
@import 'views/interview';
@import 'views/interviews';
@import 'views/new';
@import 'views/news';
@import 'views/text';
@import 'views/texts';
@import 'views/work';
@import 'views/works';
