.slideshow {
  @include no-select;
  position: relative;

  li {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    position: absolute;
    inset: 0;

    opacity: 0;
    pointer-events: none;
  }

  li.is-visible {
    @include fade-in;
  }

  &:not(.has-started) li:first-child {
    opacity: 1;
  }

  html.js & figure {
    cursor: pointer;
  }
}
