.menu {
  @include no-select;
  @include font('menu');
  @include easing(background-color);

  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  justify-content: space-between;
  white-space: nowrap;
  padding: var(--gutter);
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }

  &::before {
    @include easing(opacity);
    @include smooth-gradient(to bottom, (247, 247, 247));
    pointer-events: none;
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    bottom: -200%;
    opacity: 0.9;
  }

  h1 {
    z-index: 1;
  }

  &, &__links > ul {
    display: flex;
    gap: var(--gutter);
  }

  &__links {
    text-transform: lowercase;
    color: var(--color-dim);

    @include viewport('mobile') {
      @include font('body');
      @include easing(opacity);

      opacity: 0;
      pointer-events: none;

      position: fixed;
      inset: 0;
      background: var(--color-background);
      padding: var(--gutter);

      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-end;

      & > ul {
        margin-top: auto;
        align-items: flex-end;
        flex-direction: column;
      }
    }
  }

  .languages {
    display: none;
    color: var(--color-text);

    @include viewport('mobile') {
      display: block;
      margin-top: auto;
      justify-content: flex-end;
    }
  }

  a {
    @include easing(color);

    &:hover,
    &.is-active {
      color: var(--color-text);
    }
  }

  #toggleMenu {
    display: none;
  }

  label[for=toggleMenu] {
    display: none;

    z-index: 1;
    cursor: pointer;

    position: relative;
    width: 1.6em;
    height: 1.6em;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    @include viewport('mobile') {
      display: block;
    }

    hr {
      @include easing;
      position: absolute;
      border: 0;
      border-top: 2px solid var(--color-dim);
      width: 100%;
      margin: 0;
    }

    hr:nth-child(1) { top: 1px; }
    hr:nth-child(2) { top: calc(50% - 1px); }
    hr:nth-child(3) { top: calc(100% - 3px); }
  }

  #toggleMenu:checked + label[for=toggleMenu] {
    hr:nth-child(1) {
      transform: translateX(3px) translateY(1px) rotate(45deg);
      transform-origin: left center;
    }
    hr:nth-child(2) { opacity: 0; }
    hr:nth-child(3) {
      transform: translateX(3px) translateY(-1px) rotate(-45deg);
      transform-origin: left center;
    }
  }

  #toggleMenu:checked ~ &__links {
    opacity: 1;
    pointer-events: auto;
  }
}
