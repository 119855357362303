.filter {
  display: flex;
  justify-content: flex-start;
  gap: 0.5ch;

  select {
    all: unset;
  }

  label {
    color: var(--color-dim);
  }

  select {
    @include underline(transparent);
    cursor: pointer;
    min-width: 0;
    width: auto;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    width: calc(var(--auto-width, 100%) + var(--gutter));
  }

  select:hover {
    @include underline;
  }
}
