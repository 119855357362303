main[data-barba-namespace='work'] {
  @include grid(2, 4);
  margin-top: var(--vertical-space-top);
  padding-bottom: var(--gutter);

  @include viewport('mobile') {
    @include grid(1, 5);
  }

  .metas {
    @include font('menu');
    @include grid(1, 1);
    position: fixed;
    bottom: var(--gutter);
    left: 0;

    @include viewport('mobile') {
      width: unset;
      position: unset;
      padding: unset;
      margin-left: unset;
      margin-bottom: var(--vertical-space);
    }
  }

  .metas h2 {
    @include underline;
  }

  .gallery figure {
    @include viewport('mobile') {
      margin-left: calc(-1 * var(--gutter));
      margin-right: calc(-1 * var(--gutter));
    }

    & + figure {
      margin-top: var(--vertical-space);
    }
  }

  .credits {
    @include font('menu');
    color: var(--color-dim);
    text-align: right;
    margin-top: var(--vertical-space-small);
  }

  nav {
    @include font('menu');

    position: fixed;
    bottom: var(--gutter);
    right: var(--gutter);

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include viewport('mobile') {
      position: unset;
      flex-direction: unset;
      justify-content: space-between;
    }

    a {
      @include easing(color);
      color: var(--color-dim);

      &:hover {
        color: var(--color-text);
      }
    }
  }
}
