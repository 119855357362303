main[data-barba-namespace='contact'] {
  margin-top: var(--vertical-space-top);
  padding: 0 var(--gutter) var(--gutter);

  a:hover {
    text-decoration-color: currentColor;
  }

  .galleries {
    margin-bottom: var(--vertical-space);

    h2 {
      @include font('large');
      margin-bottom: 0.2em;
    }
  }

  .socials {
    @include font('menu');

    h2 {
      @include underline;
      margin-bottom: var(--vertical-space-small);
    }
  }

  .credits {
    @include font('menu');
    position: fixed;
    left: var(--gutter);
    bottom: var(--gutter);
    color: var(--color-dim);
  }
}
