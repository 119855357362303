main[data-barba-namespace='biography'] {
  --cols: 8;
  margin-top: var(--vertical-space-top);
  padding-bottom: var(--gutter);

  nav {
    @include no-select;
    @include grid(1, 2);

    z-index: 2;
    position: fixed;
    top: calc(var(--vertical-space) + var(--vertical-space-small));
    bottom: var(--gutter);
    isolation: isolate;

    display: flex;
    flex-direction: column;
    gap: var(--vertical-space-small);
    white-space: nowrap;

    @include viewport('mobile') {
      @include underline;
      position: unset;
      top: unset;
      bottom: unset;
      margin-bottom: var(--vertical-space-small);
    }

    .js-scroll-wrapper {
      position: relative;
      flex: 1;

      @include viewport('mobile') {
        display: none;
      }
    }

    a.is-active, a:hover {
      text-decoration-color: currentColor;
    }
  }

  article {
    @include grid(4, 7);

    position: relative;
    margin-bottom: var(--vertical-space);

    @include viewport('tablet') {
      @include grid(4, 8);
    }

    @include viewport('mobile') {
      @include grid(1, 8);
    }

    &::before {
      @if $debug {
        content: '';
        border-top: 1px dashed #4b96ff;
        position: absolute;
        top: 1em;
        left: -100vw;
        right: 0;
      }
    }

    h2 {
      @include underline;
      margin-bottom: var(--vertical-space-small);

      @include viewports(('tablet', 'desktop', 'large')) {
        display: none;
      }
    }

    figure {
      margin: var(--vertical-space) 0;
    }

    li {
      margin-bottom: var(--vertical-space-small);
    }

    li[data-year]::before {
      content: attr(data-year);
      position: absolute;
      left: calc(-1 * var(--gutter));
      text-align: right;
      transform: translateX(-100%);
      color: var(--color-dim);

      @include viewport('mobile') {
        position: unset;
      }
    }
  }
}
