.languages {
  @include no-select;
  @include font('menu');
  text-transform: uppercase;

  ul {
    display: flex;
    gap: calc(var(--gutter) / 2);
  }

  a {
    @include easing(color);
  }

  li:not(.is-active) a:not(:hover) {
    color: var(--color-dim);
  }

  li + li {
    position: relative;
    padding-left: calc(var(--gutter) / 2);

    &::before {
      content: '|';
      color: var(--color-dim);
      position: absolute;
      left: -1px;
    }
  }
}
